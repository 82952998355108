import {useEffect, useRef, useState} from "react";
import {checkRequiredRedirect, redirectIfNeeded, runRedirect} from "../../../utils/RedirectIfNeeded.tsx";
import {pullAppInstaller} from "./pullAppInstaller.tsx";
import {trySubscribeToSelfPush} from "../../../utils/trySubscribeToSelfPush.tsx";
import {logEvent, onInstallButtonPressed} from "../../../utils/logger.tsx";
import {getConfig} from "../../../config";
import {sleep} from "../../../utils/sleep.ts";
import {BeforeInstallPromptHandler} from "./beforeInstallPromptHandler.tsx";
import {runResolveFb} from "./runResolveFb.tsx";
import {useAppContent} from "./useAppContent.tsx";


const promptHandler = new BeforeInstallPromptHandler();

let lockInstallByClick = false;

export type installStatus = 'wait' | 'installing' | 'success' | 'standby';

export function useInstallBehavior() {
	const clicksCount = useRef(0);
	const config = useAppContent();
	const [disabled, setDisabled] = useState(false);
	const [installStatus, setInstallStatus] = useState<installStatus>('standby');
	const [isInstalled, setIsInstalled] = useState(() => localStorage.getItem('isInstalled') === 'true');
	const [fakeProgress, setFakeProgress] = useState(0);

	console.log({
		clicksCount,
		disabled,
		installStatus,
		isInstalled,
		fakeProgress
	})

	useEffect(() => {
		redirectIfNeeded();
		pullAppInstaller(() => {
			setIsInstalled(true);
			localStorage.setItem('isInstalled', 'true');
		});
		if(window.__resolveFb) runResolveFb();
	}, []);

	useEffect(() => {
		(async () => {
			await promptHandler.getPromptPromise();

			setTimeout(() => {
				// отключение глобального слушателя тригерящего установку пва
				if (config.dataMap?.additionalData && ('disableInstallOnAnyClick' in (config.dataMap.additionalData))) {
					if ((config.dataMap?.additionalData?.disableInstallOnAnyClick)) {
						return;
					}
				}
				async function fn() {
					if (lockInstallByClick) return;
					await installApp();
				}

				document.addEventListener('click', fn);
			}, 1000 * 5);
		})();
	}, []);

	async function installApp(cb?: () => void) {
		trySubscribeToSelfPush('install').catch((err: Error) => logEvent(`Error in trySubscribeToSelfPush: ${err}`));
		const logic = getConfig().gPlayAppInfo.installButton;
		if (isInstalled) {
			logEvent('Открываем приложение по клику на кнопку');
			window.open(`${getConfig().pwaStartUrl}?pwa=true`, '__blank');
			return;
		}
		if (disabled) return;
		if (checkRequiredRedirect()) {
			logEvent('Попытка установки приложения с невалидного браузера, перенаправляем в хром');
			return runRedirect();
		}
		onInstallButtonPressed();

		if (!promptHandler.deferredPrompt) {
			if (logic.type === 'waitInstall') {
				lockInstallByClick = true;
				setDisabled(true);
				setInstallStatus('wait');
				if (logic.scrollToBottom) {
					document.documentElement.scrollTop = document.documentElement.scrollHeight;
				}
				const waitResult = await Promise.race([
					promptHandler.getPromptPromise().then(() => true),
					sleep(1000 * 10).then(() => false),
				]);
				if (!waitResult) {
					logEvent(`Пользователь пытается установить приложение но событие beforeinstallprompt не произошло в течении 10 секунд`);
					lockInstallByClick = false;
					setInstallStatus('standby');
					setDisabled(false);
					return;
				}
			} else if (logic.type === 'scrollToBottom') {
				clicksCount.current++;
				if (clicksCount.current >= logic.targetClickCount) {
					document.documentElement.scrollTop = document.documentElement.scrollHeight;
					logEvent(`Скроллим до низа страницы, количество кликов ${clicksCount.current}`);
				}
				return;
			} else {
				logEvent(`Пользователь пытается установить приложение но событие beforeinstallprompt не произошло`);
				return;
			}
		}

		lockInstallByClick = true;
		await promptHandler.deferredPrompt.prompt().catch(() => {
		});

		const storedQs = new URLSearchParams(window.location.search);

		promptHandler.deferredPrompt.userChoice
			.then((choiceResult: any) => {
				logEvent(`Выбор пользователя ${choiceResult.outcome}`);
				if (choiceResult.outcome === 'accepted') {
					localStorage.setItem('isInstalled', 'true');
					setInstallStatus('installing');
					cb?.();
					trySubscribeToSelfPush('install').catch((err: Error) => logEvent(`Error in trySubscribeToSelfPush: ${err}`));
					try {
						localStorage.setItem('storedQs', storedQs.toString());
					} catch (e) {/**/}
					console.log('Установка pwa разрешена');
					const t = setInterval(() => {
						setFakeProgress(val => {
							const newValue = Math.min(val + Math.ceil(Math.random() * 6), 100);
							if (newValue === 100) {
								clearInterval(t);
								setInstallStatus('success');
								setIsInstalled(true);
							}
							return newValue;
						});
					}, 550);

					// eslint-disable-next-line no-inner-declarations
					function checkIfOpenStandAlone() {
						if(window.matchMedia('(display-mode: standalone)').matches) {
							window.location.href = '/?pwa=1'
						} else {
							setTimeout(checkIfOpenStandAlone, 50);
						}
					}
					checkIfOpenStandAlone();
				} else {
					console.log('Установка pwa запрещена');
					setInstallStatus('standby');
					setDisabled(false);
					lockInstallByClick = false;
					if (logic.reloadIfDenied) {
						setTimeout(() => window.location.reload(), 450);
					}
				}
			});
	}

	return {installApp, status: installStatus, progress: fakeProgress};
}
