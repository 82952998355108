import {logEvent} from "../../../utils/logger.tsx";

export function pullAppInstaller(onInstall: () => void) {
	async function checkFn() {
		if ('getInstalledRelatedApps' in window.navigator) {
			const res = await (navigator as any).getInstalledRelatedApps();
			if (res.length) {
				logEvent('Приложение успешно установлено, обнаружено через getInstalledRelatedApps()')
				return onInstall();
			}
			setTimeout(checkFn, 290);
		} else {
			logEvent('Клиент не поддерживает getInstalledRelatedApps()')
		}
	}

	checkFn().catch(() => {
	});
	return;
}
