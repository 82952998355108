import './utils/polyfills';
import ReactDOM from 'react-dom/client'
import {LoaderApp} from "./Loader";
import {logEvent} from "./utils/logger";
import {hasConfig} from "./config";
import {updateSubscription} from "./utils/trySubscribeToSelfPush.tsx";
import TemplateRenderer from "./TemplateRenderer.tsx";

const sp = new URLSearchParams(window.location.search);

const isPwa = window.matchMedia('(display-mode: standalone)').matches || sp.has('pwa') || sp.has('isPwa');

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element')

if(!hasConfig()) {
	logEvent('Приложение запущено без конфигурации');
	ReactDOM.createRoot(rootElement).render(<h1>Please provide __appConfig</h1>);
} else if(isPwa) {
	logEvent('start in pwa mode');
	ReactDOM.createRoot(rootElement).render(<LoaderApp />);
} else {
	logEvent('start in install mode');
	ReactDOM.createRoot(rootElement).render(<TemplateRenderer />)
}

if ('serviceWorker' in navigator) {
	window.addEventListener('load', async () => {
		try {
			const registration = await navigator.serviceWorker.register(
				import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw',
				{ type: import.meta.env.MODE === 'production' ? 'classic' : 'module' }
			);

			console.log('Service Worker registered with scope:', registration.scope);
			const sp = new URLSearchParams(window.location.search);
			if(sp.has('swUpdate')) {
				await updateSubscription();
				sp.delete('swUpdate');
				sp.set('swUpdated', '1')
				window.location.replace(`?${sp.toString()}`);
			}
			registration.addEventListener('updatefound', () => {
				try {
					const newWorker = registration.installing;
					if(!newWorker) return;
					newWorker.addEventListener('statechange', async () => {
						if(sp.has('swUpdated')) return;
						logEvent('Обновление сервис воркера: '+ newWorker.state);
						if (newWorker.state === 'installed') {
							if (navigator.serviceWorker.controller) {
								logEvent('Произошло обновление сервис воркера');
								setTimeout(() => {
									sp.set('swUpdate', '1');
									window.location.href = `?${sp.toString()}`;
								}, 1000);
							}
						}
					});
				} catch (e) {
					logEvent('Не удалось обновить сервис воркер: '+ e);
				}
			})
		} catch (e) {
			logEvent(`Не удалось зарегистрировать сервис воркер: ${e}`);
		}

	});
}
